import { useState, useEffect, createRef } from 'react'
import Image from "next/image"
import { supportsWebp } from 'helpers'
import vignetteImg from '../../public/assets/images/vignette.png'

export default function Video({ setCurrentTime }) {
  let playerRef = createRef()
  let videoContainerRef = createRef()
  const [width, setWidth] = useState(2560)
  const [height, setHeight] = useState(1440)

  useEffect(() => {
    resize()
    window.addEventListener('resize', resize.bind())
    let timerHandle = setInterval(() => {
      if (playerRef.current) {
        setCurrentTime(parseInt(playerRef.current.currentTime))
        timerHandle = 0
      }
    }, 1000)

    return () => {
      window.removeEventListener('resize', resize.bind())
      if (timerHandle) {
        clearInterval(timerHandle)
        timerHandle = 0
      }
    }
  })

  const resize = (e) => {
    const ratio = 2560 / 1440,
      ww = window.innerWidth,
      wh = window.innerHeight

    if (ww / wh < ratio) {
      setWidth(ww)
      setHeight(ww / ratio)
    } else {
      setWidth(wh * ratio)
      setHeight(wh)
    }
  }

  let format = 'jpg'
  if (supportsWebp) {
    format = 'webp'
  }

  return (
    <div className="video-container d-none d-md-block" ref={videoContainerRef}>
      <div className="content">
        <div className="layer">
          <div
            className="aspect"
            style={{ width: width, height: height, padding: 0 }}
          >
            <video
              id="cover-video"
              ref={playerRef}
              autoPlay
              loop
              muted
              playsInline
              poster={`https://airshaper.storage.googleapis.com/website/video/poster_new.${format}`}
            >
              <source
                src="https://airshaper.storage.googleapis.com/website/video/video.h264.mp4"
                type="video/mp4; codecs=avc1.4D401E,mp4a.40.2"
              />
              <source
                src="https://airshaper.storage.googleapis.com/website/video/video.av1.mp4"
                type="video/mp4; codecs=av01.0.05M.08,opus"
              />
              <source
                src="https://airshaper.storage.googleapis.com/website/video/video.hevc.mp4"
                type="video/mp4; codecs=hevc,mp4a.40.2"
              />
            </video>
          </div>
        </div>
        <div className="layer">
          <div
            className="aspect"
            style={{ width: width, height: height, padding: 0 }}
          >
            <div className="vignette">
              <Image
                width={parseInt(width)}
                height={parseInt(height)}
                priority={true}
                loading="eager"
                src={vignetteImg}
                alt=""
                style={{
                  maxWidth: "100%",
                  height: "auto"
                }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
