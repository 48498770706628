/* eslint-disable no-useless-escape */
const numberToCurrency = (number, options) => {
  try {
    let opts = options || {}
    const unit = opts['unit'] || '$'

    if (unit === '$') {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumSignificantDigits: 1,
      }).format(number)
    }
    if (unit === 'credits') {
      return pluralize(number, 'credit')
    }
    return new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'EUR',
      minimumSignificantDigits: 1,
    }).format(number)
  } catch (e) {
    return number
  }
}

const dateFormat = (date) => {
  const f = new Date(date)

  return `${f.toLocaleDateString('en-GB')}`
}

const stripHtml = (html) => {
  // Create a new div element
  return html
    .replace(/<[^>]*>?/gm, '')
    .replace(/(\r\n|\n|\r)/gm, '')
    .trim()
    .slice(0, 200)
}

const slugify = (text) => {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w\-]+/g, '') // Remove all non-word chars
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
}

const getPathVariable = (path, variable) => {
  let parts = path.substr(1).split('/'),
    value

  while (parts.length) {
    if (parts.shift() === variable) value = parts.shift()
    else parts.shift()
  }

  return value
}

// https://gist.github.com/eduardinni/ff0011ba8c411fa06253c1d5850373cf
const getYoutubeId = (url) => {
  url = url.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/)
  return undefined !== url[2] ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0]
}

const isEmpty = (obj) => {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false
  }
  return true
}

const supportsWebp = async () => {
  if (!window.createImageBitmap) return false

  const webpData =
    'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA='
  const blob = await fetch(webpData).then((r) => r.blob())
  return createImageBitmap(blob).then(
    () => true,
    () => false
  )
}

const pluralize = (count, noun, suffix = 's') =>
  `${count} ${noun}${count !== 1 ? suffix : ''}`

export {
  numberToCurrency,
  slugify,
  getPathVariable,
  isEmpty,
  getYoutubeId,
  stripHtml,
  supportsWebp,
  dateFormat,
  pluralize,
}
